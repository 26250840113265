import axios from 'axios'
import store from '../store/index.js'
import router from '../router/index.js'

const http = axios.create({
	timeout: 10000,
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json; charset=utf-8'
	}
})
//判断环境提供baseURL，注意要与后台地址一致
let root = process.env.NODE_ENV === 'development'
	// 开发环境api接口
	?
	`http://localhost:8091/api`
	// 生产环境api接口
	:
	`https://longtong.api.yuntuzl.net/api`;


/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	config.headers['Authorization'] =  store.state.token || '' // 请求头带上token
	/* console.log("-------------addtoken-------------") */
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {

	/* console.log("-------------response interceptors-------------") */
	if (response.data && response.data.code === 12) { // 401, token失效
		/* clearLoginInfo() */
		store.commit('setToken', '');
		router.push({
			path: '/login'
		}).catch(srr => {})

	}
	return response
}, error => {
	return Promise.reject(error)
})

const upload = axios.create({
	timeout: 30000,
	headers: {
		"Content-Type": "multipart/form-data"
	},
})


/**
 * 请求拦截
 */
upload.interceptors.request.use(config => {
	config.headers['Authorization'] = store.state.token || '' // 请求头带上token
	/* console.log("-------------addtoken-------------") */
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
upload.interceptors.response.use(response => {
	/* console.log("-------------response interceptors-------------") */
	if (response.data && response.data.code === 12) { // 401, token失效
		/* clearLoginInfo() */
		router.push({
			path: '/login'
		})
	}
	return response
}, error => {
	return Promise.reject(error)
})


const download = axios.create({
	timeout: 300000,
	withCredentials: false,
	headers: {
		'Content-Type': 'application/json; charset=utf-8'
	}
})

/**
 * 请求拦截
 */
download.interceptors.request.use(config => {
	config.headers['Authorization'] = store.state.token || '' // 请求头带上token
	/* console.log("-------------addtoken-------------") */
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
download.interceptors.response.use(response => {

	/* console.log("-------------response interceptors-------------") */
	if (response.data && response.data.code === 12) { // 401, token失效
		/* clearLoginInfo() */
		router.push({
			path: '/login'
		})

	}
	return response
}, error => {
	return Promise.reject(error)
})





function apiAxios(method, url, params) {
	return http({
		method: method,
		url: url,
		data: method === 'POST' && params ? JSON.stringify(params) : null,
		baseURL: root,
	})
}

function uploadAxios(url, formData) {
	return upload({
		method: 'POST',
		url: url,
		data: formData,
		baseURL: root,
	})
}

function downloadAxios(url,params){
	return download({
		method: "POST",
		url: url,
		data: params,
		baseURL: root,
		responseType: 'blob',
	})
}

function uploadAndDown(url, formData) {
	return upload({
		method: 'POST',
		url: url,
		data: formData,
		baseURL: root,
		responseType: 'blob',
	})
}

export default {
	get: function(url) {
		return apiAxios('GET', url)
	},
	post: function(url, params) {
		return apiAxios('POST', url, params)
	},
	upload: function(url, formData) {
		return uploadAxios(url, formData)
	},
	download:function(url,params){
		return downloadAxios(url,params)
	},
	uploadAndDown: function(url, formData){
		return uploadAndDown(url, formData)
	}
}
